.jobDetailsBody {
  max-width: 1310px;
  margin: 0 auto;
  padding: 0px 15px;
}
.container {
  margin-top: 25px;
  margin-bottom: 25px;
  background: #ffffff;
  padding: 15px 25px;
}
.breadCrumbContainer {
  max-width: 1310px;
  margin-inline: auto;
  margin-top: 25px;
  padding: 0px 15px;
}
.main {
  min-height: 100vh;
}
.LinkHover:hover {
  color: black !important;
}
.helperText {
  text-align: left;
  padding: 0 10px;
  font-weight: 400 !important;
  color: #b5b5b9;
}
.RoleHead {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 20px;
  color: #231f20;
}
.RoleText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  text-align: justify;
  color: #6d6e70;
}
.lernMoreButton {
  color: blue !important;
  border: 1px solid blue !important;
}
.noSimilarJob {
  text-align: center;
  color: #6d6e70 !important;
}
.jobsCard {
  padding: 1rem 1rem;
}

.filterModal {
  top: 25%;
  :global(.ant-modal-body) {
    overflow: scroll;
  }
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }
}

@media only screen and (max-width: 990px) {
  .filterModal {
    margin: 15px 0;
  }
}

@media only screen and (max-width: 480px) {
  .filterModal {
    margin: 15px 0;
  }
}
