@import "styles/globals.scss";

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $headerBackgroundColor;
  height: $barLength !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 100;

  .logoContainer {
    display: flex;
    align-items: center;

    .burgerMenu {
      font-size: 25px;
      cursor: pointer;
      margin-right: 7px;
    }
  }
}
