.main {
  background-color: #f4f5fa;
  min-height: 100vh;

  .createCompanyContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .mainTitle {
      text-align: center;
      margin: 20px 0px;
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-style: normal;
      line-height: "48px";
      font-size: 32px;
    }
    .mainSubTitle {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      padding: 0px 0px 20px;
      margin-bottom: 20px;
    }
  }
  .fromContainer {
    margin: 0 auto;
    .title {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      color: #231f20;
    }
     .homeButton {
      margin-bottom: 50px;
      border: 1px solid #BC012D;
      background: #BC012D;
      padding: 0 10px;
      color: white;
    }
    .helperText{
      text-align: left;
      padding: 0 10px;
      font-weight: 400 !important;
      color:#b5b5b9;
    }
  }
  
  .modalContainer {
    text-align: center;
  }

  .socialShareImage {
    display: none;
  }
}

:global(.selectInput_select__3Xq4R) {
   margin-right: 0px !important;
}
:global(.ql-editor){
  min-height:200px;
}

@media only screen and (max-width: 480px) {
  :global(.account_container__2MF4F .account_form__3dL0l) {
    width: 100%;
  }
}
