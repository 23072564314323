.container {
  max-width: 1310px;
  margin-inline: auto;
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 0px 15px;
  padding-bottom: 20px;
  
  .containerBody {
    background: #ffffff;
  }
}
.links {
  scroll-margin-top: 90px;
}
.breadCrumbContainer {
  max-width: 1310px;
  margin-inline: auto;
  margin-top: 25px;
  background: #f1f1f1;
  padding: 0px 15px;
}
.main {
  background-color: #f1f1f1;
  min-height: 100vh;
}
.LinkHover:hover {
  color: black !important;
}
.heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 20px;
  color: #231f20;
}
.companyInfo {
  display: flex;
  justify-content: flex-start;
  margin: auto 0;

  .companyLogo {
    width: 70px;
    height: auto;
    margin-top: 7px;
    margin-left: 7px;
  }
  .companyTitleContainer {
    margin-left: 15px;

    h1 {
      margin-bottom: 0px;
    }
    .companyLocationContainer {
      display: flex;

      .companyLocationImage {
        margin-bottom: 5px;
        margin-right: 8px;
      }
    }
  }
}
.jobButton {
  display: flex;
  justify-content: flex-end;
  margin-right: 29px;
}
.dividerStyle {
  padding: 0 20px;
}
.aboutText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #6d6e70;
}
:global(.ant-anchor-ink::before) {
  position: relative;
  display: none;
}
:global(.ant-anchor-ink) {
  padding: 8px 13px 12px 0px !important;
  line-height: 1.143;
}
:global(.ant-anchor-link-title:hover) {
  color: #BC012D !important;
}
:global(.ant-anchor-link-active>.ant-anchor-link-title) {
  color: #BC012D;
  border-bottom: 1px solid #BC012D;
}
:global(.ant-anchor-link-title:only-child) {
  padding: 10px 3px;
}
.buttonStyle {
  background: #BC012D !important;
  color: white !important;
}
.descriptionText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  text-align: justify;
}
.activeLink {
  padding-top: 100px;
}