.companyName{
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0px;

  &.isExtraExtra {
    font-size: 13px;
  }
}
.companyLocationLogo{
  margin-bottom: 5px;
  padding-right: 5px;
}
.companyPeopleLogo{
  margin-bottom: 5px;
  padding: 0px 7px;
}
.companyLocation{
  font-weight: 400;
  font-size: 12px;
  line-height:20px;
  padding: 4px;
  color: #6D6E70;
}
.companyDefination {
  width: 95%;
  text-align: justify;
}
.companyAbout{
  color:black;
  font-weight: 400;
  font-size: 12px;
  line-height:20px;
  height: 85px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dasboardCardBody {
  background-color: #ffffff;
  border: 1px solid #dadce0;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  display: flex;

  :global(.ant-card-body) {
    width: 100%;
  }
}
.imageLogo {
  border: 1px solid #dadce0;
  border-radius: 2px;
  width: 55px;
  height: auto;
  padding: 4px;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 0px 15px;
  margin-left: 10px;

  &.isExtraExtra {
    margin-left: 18px;
  }
}
.comapnyTitle {
  display: flex;
  justify-content: space-between;
}
.tag {
  border-radius: 200px;
  width: 57px;
  height: 24px;
  padding: 1px 10px 2px;
}
.subTititle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
