@import 'styles/globals';

.table {
  // border: 1px solid $lightgray;
  // border-radius: $borderRadius;

  & th {
    word-break: break-word;
    white-space: nowrap;
  }

}

// .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
//   background-color:transparent !important;
// }
.pagination {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 20px 50px 20px 50px;
}

.tableTopContainer {
  padding-bottom: 1.5rem
}

.buttonContainer {
  display: flex; 
  justify-content: space-between;
  padding-bottom: 2rem;
}
.buttonIcon {
  color: white;
  background:  #B93995 !important;
  
}
.buttonText {
  color: white;
}

:global(.ant-pagination-item-active) {
  border: 1px solid #bc012D !important; 
  & a {
    color: #bc012D ;
  }
  &:focus {
    & a {
      color: #bc012D ;
    }
  }
}

:global(.ant-table-tbody > tr.ant-table-row:hover > td) {
  background: none !important;
}

:global(.ant-table-thead> tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before) {
  background-color: transparent!important;
}
:global(.ant-table-thead> tr>th:first-child) {
  padding-left: 22px!important;
}