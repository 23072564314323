.container {
  // width: min(100% - 3rem, 87vw);
  max-width: 1310px;
  margin-inline: auto;
  margin-top: 25px;
  padding: 0 15px;
}
.main {
  min-height: 100vh;
}
.title {
  text-align: center;
  margin: 20px 0px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
}
.companyName{
  font-weight: 500;
  font-size: 18px;
}
.companyLocationLogo{
  margin-bottom: 5px;
  padding-right: 5px;
}
.companyPeopleLogo{
  margin-bottom: 5px;
  padding: 0px 7px;
}
.companyLocation{
  font-weight: 400;
  font-size: 12px;
  line-height:20px;
  padding: 4px;
  color: #6D6E70;
}
.companyAbout{
  color:black;
  font-weight: 400;
  font-size: 12px;
  line-height:20px;
}
.dasboardCardBody {
  background-color: #ffffff;
  border: 1px solid #dadce0;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  height: 210px;
  display: flex;
}
.imageLogo {
  border: 1px solid #dadce0;
  border-radius: 2px;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 0px 15px;
  margin-left: 20px;
}
.comapnyTitle {
  display: flex;
  justify-content: space-between;
}
.tag {
  border-radius: 200px;
  width: 57px;
  height: 24px;
  padding: 1px 10px 2px;
}
.subTititle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.loadMoreButtonContainer {
  margin-top: 10px;
  margin-bottom: 30px;
  text-align: center;
}