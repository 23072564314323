.contianner {
  display: flex;
}
.profileContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.avatarImage {
  width: 64px;
  height: 64px;
  background: #bfbfbf;
  border-radius: 56px;
}
.buttonwrap {
  position: relative;
  display: block;
  padding-top: 45px;
}
.buttonList {
  display: flex;
  justify-content: left;
  gap: 20px;
}
.uploadFormWrap {
  z-index: 1;
}
.uploader {
  border-radius: 50%;
  width: 100%;
}
.uploadButton {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 12px;
  gap: 4px;
  background: #BC012D !important;
  border: 1px solid #BC012D !important;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016) !important;
  border-radius: 200px;
  color: #fff !important;
  margin-left: 10px;
}
.uploadButton:hover {
  background: #BC012D !important;
  border: 1px solid #BC012D !important;
  color: #fff !important;
}
.cancelButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: flex-start;
  padding: 2px 12px;
  gap: 4px;
  background: #231f20;
  border: 1px solid #231f20;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 200px;
  color: white;
  cursor: pointer;
}
.cancelButton:hover {
  background: #231f20 !important;
  border: 1px solid #231f20 !important;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016) !important;
  color: white !important;
}
.text {
  align-self: flex-end;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  color: rgba(0, 0, 0, 0.24);
  position: relative;
  top: -20px;
  left: 10px
}

