@import "styles/globals.scss";

.root {
  padding: 1.5rem;
  margin-top: 20px;

  :global(.ant-table-container table > thead > tr:first-child th:last-child) {
    text-align: center !important;
  }
}
.ant-modal-header {
  border: none;
}
.ant-modal-footer {
  border: none;
}
.ant-modal-content {
  padding: 0px 0px;
}
.wrapper {
  margin-top: -35px;
  padding-left: 38px;
}
.queriesContainer {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 20px;
  text-align: justify;
  color: rgba(0, 0, 0, 0.85);
}

:global(.ant-btn-primary:focus) {
  background: none;
  border: none;
  box-shadow: none;
}

:global(.ant-modal-header) {
  border-bottom: none;
}
:global(.ant-modal-footer) {
  border-top: none;
}
:global(.actionButtonCell_container__1raCJ) {
  text-align: right;
}
:global(.ant-table-cell:last-child) {
  text-align: center;
}
.cancelButton {
  background: #ffffff;
  border: 1px solid #dadce0;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 200px;
}
.messageBoxContainer {
  position: relative;
  padding: 0 20px;

  .messageNewIcon {
    font-size: 10px;
    position: absolute;
    top: -7px;
    left: 35px;
    color: #bc012d;
  }
}