@import 'styles/globals';

.container {
  display: flex;
  flex-direction: column;
  min-width: 65px;
}
.bold {
  font-weight: 500;
}
.row {
  min-height: $minCellHeight;
}
