@import "styles/globals.scss";

.root {
  padding: 1.5rem;
  margin-top: 20px;
}
.ant-modal-header {
  border: none;
}
.ant-modal-footer {
  border: none;
}
.ant-modal-content {
  padding: 0px 0px;
}
.container {
  margin-top: -30px;
  padding-left: 40px;
}
:global(.ant-btn.ant-btn-round.ant-btn-primary.ant-btn-icon-only) {
  background: none;
  color: gray;
  border: none;
  box-shadow: none;
  padding: 0px;
}
:global(.ant-btn-primary:focus) {
  background: none;
  border: none;
  box-shadow: none;
}

.r-warning {
  color: red;
}

.cancelButton {
  background: #ffffff;
  border: 1px solid #dadce0;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 200px;
}