@import "styles/globals.scss";

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.btnContainer {
  display: flex;
  align-items: center;
  font-size: 16px;
  gap: 15px;
  .learnMore {
    margin-left: 26px;
    margin-right: 21px;
    cursor: pointer;
  }
  .allLink {
    color: #6d6e70;
    cursor: pointer;
    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 22px;
      padding: 5px 10px;
      text-align: center;
      margin: auto 0;
      color: #000000;
      &:hover{
        color:#BC012D;
        text-decoration: underline 0.15em #BC012D;
        text-underline-offset: 1em;
      }
    } 
  }
  .postJobLink {
    background: #ffffff;
    border: 1px solid #BC012D;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 200px;
    cursor: pointer;
    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      padding: 5px 16px;
      text-align: center;
      color: #BC012D;
      width: 100px;
      height: 32px;
      margin: auto 0;
    }
  }
}

.btnStyle {
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
  margin-left: 26px;
  margin-right: 21px;
}

:global(.ant-layout-header) {
  line-height: 18px;
  padding: 0 20px;
}
.navbarListItemNameActive{
  color: #BC012D;
}
.navbarListItemNam{
  color: #6D6E70;
}
