@import "styles/globals.scss";

.container {
  display: flex;
  align-items: center;
  margin-left: auto;
  font-weight: bold;
  height: 40px;
  border: 1px solid #d9d9d9;
  border-radius: 5555px;
  padding-right: 0.8rem;
  cursor: pointer;

  &.is-mobile {
    width: 75px;
    justify-content: space-around;
    padding-right: 8px;
  }

  .logo {
    width: 50px;
  }

  .version {
    font-style: italic;
    font-size: $fontSizeSmall;
    color: $gray;
  }

  .userName {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(0, 0, 0, 0.87);
    margin-left: 8px;
  }

  .avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-left: 4px;
  }

  .menuContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 0px;
    width: 186px;
    background: #ffffff;
    border-radius: 5px;
    margin-right: 12px;
    z-index: -100;
  }

  .downIcon {
    margin-left: 8px;
  }
}
.avatarImage {
  width: 34px;
  height: 34px;
  background: #bfbfbf;
  border-radius: 56px;
}
.userName {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #231f20;
}
:global(.profile_container__2rJCg) {
  border: none !important;
}
