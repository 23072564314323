:global(.ql-editor) {
  min-height: 200px;
}
@import "styles/globals.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  .backIcon {
    display: flex;
    padding: 0px 2px;
    color: #184686;
    cursor: pointer;
  }
  .nameContainer {
    :global(.ant-form-item) {
      margin-bottom: 8px;
    }
    span {
      font-size: 12px;
    }
  }

  .upperTitleContainer {
    margin-bottom: 8px;
  }
  .profileContainer {
    padding-top: 20px;

    :global(.ant-form-item) {
      padding-top: 10px;
    }
  }

  .inputGroup {
    display: flex;
    justify-content: space-between;
  }

  .inputGroupInput {
    width: 220px !important;
  }

  .formContainer {
    margin-top: 26px;
    h2 {
      font-size: 24px;
    }
  }
  .form {
    width: 490px;
    padding-top: 15px;
    &.is-mobile {
      width: 100%;
      padding: 0px 20px;
    }
  }

  .areaTitle {
    margin-bottom: 8px;
  }

  .button {
    min-width: 120px;
    border-color: #BC012D;
    background-color: #BC012D;
    color: #fff;
  }
  .textContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: $primaryViolet;
  }
  .buttonTextGhost {
    color: white !important;
  }

  .uploader {
    :global(.ant-upload.ant-upload-select-picture-card) {
      width: auto;
      height: 0;
      border: none;
      background: none;
      display: flex;
      padding-top: 20px;
    }

    :global(.ant-upload-list) {
      padding-top: 0;
    }

    .uploadButton {
      border: 1px solid #d9d9d9;
      border-radius: 3px;
      padding: 5px 20px 5px 5px;
      color: black;
      font-weight: bold;

      span {
        padding-left: 10px;
      }
    }
  }

  .titleContainer {
    padding-top: 20px;
    padding-left: 25px;
  }

  .sideBar {
    margin-top: 26px;
  }

  .currentPassword {
    padding-bottom: 15px;
    :global(.ant-form-item) {
      margin-bottom: 8px;
    }
    .forgetPassword {
      font-size: 12px;
      color: $primaryViolet;
      cursor: pointer;
    }
  }

  .businessLicense {
    padding-bottom: 30px;
    :global(.ant-form-item) {
      margin-bottom: 8px;
    }

    .info {
      font-size: 12px;
      color: #0000008a;
    }
  }
  .textContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: $primaryViolet;
  }

  :global(.ant-divider-horizontal) {
    min-width: 0;
    width: inherit;
  }

  .moreOptions {
    width: 490px;

    &_info {
      font-size: 12px;
      color: #0000008a;

      padding-bottom: 20px;
      padding-top: 10px;
    }

    .downloadContainer {
      display: flex;
      flex-direction: column;
    }
  }

  .pointIdentification {
    :global(.ant-divider-horizontal) {
      margin: 5px 0 24px 0;
    }

    .verification {
      padding-bottom: 20px;
    }
  }
  .CancelButton {
    color: #fff !important;
    background-color: black;
    border-color: black;
  }

  @media only screen and (max-width: 480px) {
    .pointIdentification {
      width: 85%;
      margin: 0 auto;
    }
    :global(.account_container__2MF4F .account_form__3dL0l) {
      width: 100%;
    }
    :global(.account_formContainer__Zygcu) {
      width: 85%;
      margin: 0 auto;
    }
  }

  .bottomPad {
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 480px) {
  :global(.account_container__2MF4F .account_form__3dL0l) {
    width: 100%;
  }
}

:global(.selectInput_select__3Xq4R) {
  margin-right: 0px !important;
}
.filterModal {
  top: 25%;
  :global(.ant-modal-body) {
    overflow: scroll;
  }
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }
}

@media only screen and (max-width: 990px) {
  .filterModal {
    margin: 15px 0;
  }
}

@media only screen and (max-width: 480px) {
  .filterModal {
    margin: 15px 0;
  }
}
