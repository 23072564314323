.logo {
  // height: 40px;
  height: auto;
  max-width: 144px;
  cursor: pointer;
  &.isMobile {
    max-width: 130px;
  }
}
.container{
  display: flex;
  gap: 45px;
}
.is-tab {
  display: none;
}