@import 'styles/globals.scss';

$childrenHeight: 290px;

.content {
  display: flex;
  flex-direction: column;
  background-color: $white;
  min-height: 100vh;
  background-color:#F4F5FA;

  .main {
    align-self: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex: 1;
    margin-top: 4px;
  }
  
  .children {
    display: flex;
    flex-direction: column;
    flex-basis: 760px;
    flex-shrink: 1;
    flex-grow: 2;
    width: -webkit-calc(100% - 200px);
    width:    -moz-calc(100% - 200px);
    width:         calc(100% - 200px);
    margin-top: 90px;
  }

  &.is-mobile .children {
    width: 100%;
  }
}
