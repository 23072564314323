@import "styles/globals.scss";

.sidebarContainer {
  background: #FFFFFF;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  height: 100%;
  border-right: 1px solid #d9d9d9;
  bottom: 0;
  width: 220px;
  padding-top: 61px;
  
  &.is-mobile {
    left: -210px !important;
  }
  &.is-sidebar {
    left: 0px !important;
  } 


  :global(.ant-menu-item-selected) {  
    background-color: #F4F5FA !important;
    color: #BC012D !important;
  }


  :global(.ant-menu-item) {
    height: 40px;
    line-height: 22px;
    font-size: 16px;
    margin:0 !important;
  }

  :global(.ant-menu-item:hover) {
    color: #BC012D !important;
    background-color: #F4F5FA;
    
  }

  :global(.ant-menu-item::after){
    border-right: 0ch;
  }
}


