$borderRadius: 5px;
$boxShadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px, rgba(0, 0, 0, 0.08) 0px 6px 16px 0px, rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
$lightBoxShadow:  0 3px 15px rgba(0,0,0,0.1);;
$barLength: 90px;
$fontSizeSmall: 10px;
$fontSizeMedium: 12px;
$fontSizeLarge: 14px;
$headerPadding: 15px;
$border: 1px solid rgba(0, 0, 0, 0.1);
$fontWeightMedium: 400;
$fontWeightBold: 700;
$minCellHeight: 30px;
$headerBackgroundColor: #FFFFFF;