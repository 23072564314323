@import "styles/globals.scss";

.button {
  height: 38px;
  width: max-content;
  font-weight: $fontWeightMedium;
  border: 0;

  font-weight: $fontWeightMedium;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.08em;

  &:hover {
    cursor: pointer;
    color:black;
  }

  &:focus {
    outline: none;
  }

  &.is-disabled {
    opacity: 0.6;
    cursor: unset;
  }

  &.primary {
    border: 1px solid #BC012D;
    border-radius: 200px;
    color: #BC012D;
  }

  .red {
    color: #BC012D !important;
  }

  &.default {
    background-color: transparent;
  }

  &.is-bordered {
    border-radius: 200px;
    border: 1px solid;
  }

  &.ghost {
    border: 1px solid #BC012D;
    border-radius: 200px;
    background-color: #BC012D !important;;
    color: white;
  }

  &.default {
    color: #6D6E70;
  }
}
