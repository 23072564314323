.jobTypeNamePartTime {
  color: #FFB94D !important;
}
.jobTypeNameFullTime {
  color: #A68DFF !important;
}
.jobTypeNameFreelance {
  color: #76B0FF !important;
}
.jobTypeNameContract {
  color: #FF8AC1 !important;
}
.cardContainer {
  margin-bottom: 5px;
  .comanyFeature {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    .imageLogo {
      border: 1px solid #dadce0;
      border-radius: 2px;
      width: 70px;
      height: auto;
      padding: 4px;
    }
    .companyInfo {
      display: flex;
      flex-direction: column;
    }
    .loaction {
      display: flex;
      justify-content: flex-start;
      gap: 4px;
    }
  }
}
.jobType {
  display: flex;
  flex-direction: column;
  text-align: right;
  .jobTypeName {
    color: #a68dff;
    font-size: 12px;
  }
  .jobSalaryRange {
    color: #6d6e70;
    font-size: 12px;
  }
}
  