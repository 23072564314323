
.rightHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 250px;

  & p{
    margin:0 !important;
  }
}

:global(.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)) {
  color: '#BC012D';
  border:none !important;  
}

:global(.ant-radio-button-wrapper) {  
  border:none !important;   
  box-shadow: none !important;  
}

:global(.ant-radio-button-wrapper:not(:first-child)::before) {
  content: none !important;
}
