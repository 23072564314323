@import "styles/globals.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,500;0,600;0,700;0,900;1,100;1,200;1,400;1,700;1,800;1,900&display=swap");
.layout{
    background-color: $white;
    font-family: 'Poppins', sans-serif;
}

body {
    font-family: "Poppins" !important;
    font-size: 16px;
    font-weight: 300 !important;
    color: #4A5568 !important;
}
h1 {
    font-family: "Poppins" !important;
    font-weight: 700 !important;
    color: #000000 !important;
}
h2, h3, h4, h5, h6 {
    font-family: "Poppins" !important;
    font-weight: 500 !important;
    color: #000000 !important;
}
