@import "styles/globals.scss";

.root {
    padding: 1.5rem;
    margin-top: 20px;
}
.ant-modal-header {
  border: none;
}
.ant-modal-footer {
  border: none;
}
.ant-modal-content {
  padding: 0px 0px;
}
.container {
  margin-top: -30px;
  padding-left: 40px;
}
:global(.ant-btn-primary:focus) {
  background: none;
  border: none;
  box-shadow: none;
}