@import "styles/globals.scss";

.input {
  .label {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 8px;
  }
  :global(.ant-form-item-label) {
    label {
      color: $black !important;
    }
  }

  :global(.Input_label__2Bg6h::after) {
    content: "*";
    color: red !important;
    margin-left: 2px !important;
  }
}

:global(.ant-form-large .ant-form-item-label > label){
  height: 0px;
  font-weight: 600;
}

:global(.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional))::after {
  display: inline-block;
  margin-right: 4px;
  color: #BC012D;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

:global(.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional))::before {
  content: none;
}