.uploader {
  :global(.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon) {
    color: rgba(0, 0, 0, 0.54);
  }

  :global(.ant-upload-list) {
    padding-top: 10px;
    display: flex;
  }

  :global {
    .ant-upload-list.ant-upload-list-text{
      display:none
    }
  }
}
