.main {
  background: #f4f5fa;
  min-height: 100vh;
  .container {
    // width: min(100% - 3rem, 87vw);
    max-width: 1310px;
    margin-inline: auto;
    margin-top: 25px;
    padding: 0px 15px 0px;

    // mainTitle
    .mainTitle {
      text-align: center;
      margin: 20px 0px 0px;
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-style: normal;
      line-height: "48px";
      font-size: 32px;
    }
    .borderStyle {
      border-bottom: 4px solid #BC012D;
      width: 200px;
      height: 4px;
      margin: 0 auto;
    }
    .text {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      padding: 20px 0;
      margin-bottom: 20px;

      &.italic {
        font-style: italic;
      }
    }
    // searchInput Style
    .SiteInput {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 25px;

      .FromContainer {
        width: 625px;
        border: 1px solid #dadce0;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
        border-radius: 200px;
        background-color: #ffffff;
        .fromContainerInput {
          width: 55%;
          margin: auto 0 !important;
          padding-top: 8px;
           &.is-mobile {
            width: 40% !important;
          }
        }
        .fromContainerSelect {
          width: 25%;
          margin: auto 0 !important;
          padding-top: 5px;
          padding-left: 14px;
          &.is-mobile {
            width: 30% !important;
          }
        }

        .buttonClaass {
          box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
          border-radius: 198px !important;
          border: 1px solid #BC012D;
          background-color: #BC012D;
          margin-top: 2px !important;
          margin-bottom: 2px !important;
          color: white;
          &.is-mobile {
            width: 30% !important;
            span{
              font-size: 13px;
            }
          }
        }
      }
    }
    .noJobTitle {
      margin-top: 50px;
      text-align: center;
      color: #bebfc2 !important;
      font-weight: 600 !important;
    }

    
    // buttonTag
    .buttonContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 25px;
      flex-wrap: wrap;
      gap: 6px;

      .filterHeading {
        margin-bottom: 0px;
        color: #4A5568 !important
      }

      .buttonTag {
        border: 1px solid #dadce0;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
        text-align: center;
        color: #6d6e70;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
      }
    }
    // job container
    .jobContianer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #e4e4f3;
      padding: 10px;
      border-radius: 5px;
      h3 {
        margin: 0px;
      }
      .tag {
        border-radius: 50px;
        min-width: 57px;
        height: 24px;
        padding: 1px 10px 2px;
        text-align: center;
      }
    }

    .homeButton {
      margin-bottom: 50px;
      border: 1px solid #BC012D;
      background: #BC012D;
      padding: 0 10px;
      color: white;
    }
  }
}
.jobListHeading {
  text-align: center;
  color: #64666b !important;
}
.buttonDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}
.categoryContainer {
  margin-bottom: 40px;
}
