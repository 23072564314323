.main {
  background-color: #f4f5fa;
  min-height: 100vh;
  display: grid;
  justify-content: center;
  align-content: center;
}
.row {
  height: 75vh;
  width: 85vw;
  margin: 0 auto;
}
.divider {
  height: 500px;
  width: 2px;
  background-color: #dadce0;
}
.footerContainer {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}
.subTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: rgba(0, 0, 0, 0.24);
}
.imageLogo {
  margin-top: 40px;
}
.rowContianer {
  margin: 0 auto;
}

.firstContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 15%;
}
.paragraph {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
}
.fromContainer {
  margin: 0 auto;
}
.title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 40px;
}

.forgetPassword {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #BC012D;
  float: right;
}

.copyRight {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  color: #6d6e70;
}
.footerRight {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #231f20;
  padding-right: 10px;
}
.dividerStyle {
  background-color: #dadce0;
  margin-top: 7em !important;
}

.homeButton {
  margin-bottom: 50px;
  border: 1px solid #BC012D;
  background: #BC012D;
  padding: 0 10px;
  width: 100%;
  color: white !important;
}
.homeButton:hover {
  color: black !important;
}
.signupButton {
  width: 120px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #dadce0 !important;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016) !important;
  border-radius: 200px;
  color: #6d6e70 !important;
}
.signupContainer {
  margin-top: 20px;
}

.accountColor {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #231f20;
}
