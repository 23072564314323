.main {
  background-color: #f4f5fa;
  display: grid;
  justify-content: center;
  align-content: center;
  margin: 0 auto;
  padding: 0;
}
.row {
  height: 73vh;
  width: 80vw;
  &.is-tab {
    height: 73vh;
    width: 100%;
  }

  &.is-mobile {
    height: 70vh;
    width: 100%;
    margin: 0 auto;
  }
}
.divider {
  height: 420px;
  width: 2px;
  background-color: #dadce0;
  &.is-tab {
    height: 300px;
    margin-top: 20px;
  }
}
.footerContainer {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}
.imageLogo {
  margin-top: 40px;
  &.is-tab {
    width: 250px;
  }
  &.is-laptop {
    width: 300px;
  }
}
.rowContianer {
  padding-left: 10%;

  &.is-tab {
    padding-right: 10%;
  }
  &.is-mobile {
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 20px;
  }
}
.subTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: rgba(0, 0, 0, 0.24);
}

.firstContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 15%;
}
.paragraph {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #231f20;
  &.is-tab {
    font-size: 20px;
    line-height: 24px;
  }
}
.fromContainer {
  margin: 0 auto;
}
.title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 40px;
}

.forgetPassword {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #BC012D;
  float: right;
  margin-top: 4px;
  cursor: pointer;
}

.copyRight {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  color: #6d6e70;
}
.footerRight {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #231f20;
  padding-right: 10px;
}
.dividerStyle {
  background-color: #dadce0;
  margin-top: 7em !important;
}

.homeButton {
  margin-bottom: 50px;
  border: 1px solid #BC012D;
  background: #BC012D;
  padding: 0 10px;
  width: 100%;
  color: #ffffff !important;
}
.homeButton:hover {
  opacity: .8;  
  background: #BC012D !important;
}

.signupButton {
  background-color: #ffffff !important;
  border: 1px solid #dadce0 !important;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 200px;
  width: 120px;
  float: right;
}
.signupButton > span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #6d6e70;
}
.signupContainer {
  margin-top: 20px;
}
.signupButton:hover {
  opacity: .8;
}
