@import "styles/globals.scss";

.largeCard {
    border-radius: 2px;
    background-image: $white;
    border: 1px solid #D9D9D9;
    margin-bottom: 1rem;

    :global(.ant-card-head-title) {
        // min-height: 56px;
        // height: auto;
        font-size: 18px;
    }

    :global(.ant-card-body) {
       padding: 0;
    }

    .header{
        display: flex;
        justify-content: space-between;
    }

    .right_header {
        display: flex;
        justify-content: flex-end;
    }
}

.hasPadding {
    padding: 24px;
}
