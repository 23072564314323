@import 'styles/globals.scss';

$secondaryTextColor: rgba(0, 0, 0, 0.45);
$primaryTextColor: #BC012D !important;

.dashCard { 
  border-radius: 5px;
  color: $secondaryTextColor;
  height: 114px;  
  margin-bottom: 1rem;
  border: 1px solid #d9d9d9;

  :global(.ant-card-body) {
    padding: 15px 0 0 25px;
  }  

  &.active {
    border: 1px solid #BC012D !important;
  }
}
