.statValue {
  font-size: 30px;
  line-height: 38px;
}

.total_jobs_posted {
  color: #ED213C;
}
.jobs_post_time {
  color: #000000DE;
}
