.container {
  width: 100%;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #DADCE0;
  border-radius: 5px;
  margin-bottom: 18px;
}


.title {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #6d6e70;
}
.process {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000d9;
}

.information {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #6d6e70;
}
