.container {
    background: #ffffff;

    &.withoutModal {
        margin-top: 25px;
        margin-bottom: 25px;
        padding: 15px 25px;
    }
}
.comanyFeature {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    margin-top: 25px;
    
    .imageLogo {
        border: 1px solid #dadce0;
        border-radius: 2px;
        width: 90px;
        height: auto;
        padding: 4px;
    }
    .companyInfo {
        h3 {
            font-size: 20px;
            margin-bottom: 0px;
            color: #08518d !important;
        }
    }
}
.loaction {
    display: flex;
    justify-content: flex-start;
    gap: 4px;
    color: #afafaf;
    font-size: 18px;

    h5 {
        color: #686868;
        font-weight: 300;
    }
}
.jobTypeNamePartTime {
    color: #FFB94D !important;
}
.jobTypeNameFullTime {
    color: #A68DFF !important;
}
.jobTypeNameFreelance {
    color: #76B0FF !important;
}
.jobTypeNameContract {
    color: #FF8AC1 !important;
}
.applyContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-end;

    &.is-mobile {
        align-items: center;
    }
    .jobActionButton {
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
        margin-right: 10px;
        margin-top: 10px;
    }
}
.RoleHead {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 20px;
    color: #231f20;
}
.RoleText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 22px;
    color: #6d6e70;
}
.lernMoreButton {
    color: blue !important;
    border: 1px solid blue !important;
}