@import 'styles/globals.scss';

.radio {
  :global(.ant-radio-checked .ant-radio-inner) {
    border-color: #BC012D;
  }
  :global(.ant-radio-checked .ant-radio-inner:after) {
    background-color: #BC012D;
  }
  .title{
    margin-bottom: 8px;
  }
}
