.select {
  .title {
    padding-bottom: 8px;
  }
  :global(.selectInput_title__2DQvy::after) {
    content: "*";
    color: #BC012D;
    margin-left: 2px;
  }
}
