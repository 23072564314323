@import 'styles/globals';

:global(.ant-tag) {
  margin-right: 0;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;

  :global(.ant-tag) {
    margin-right: 0;
    background: none;
  }
}
.group {
  display: flex;
  min-height: $minCellHeight;
  align-items: baseline;
}
.colTag {
  min-height: $minCellHeight;
  display: flex;
  align-items: center;
}
.rowTag {
  margin-right: 5px;
}

.leftAlignedContainer {
  align-items: flex-start;
}

