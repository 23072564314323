$borderColor: rgba(0, 0, 0, 0.06);
$gray: gray;
$black: black;
$blue: #1890FF;
$white: white;
$lightgray: lightgray;
$darkgray: #434343;
$backgroundColor: #FFFFFF;
$lightblue: #BAE7FF;
$antdBlue: #1890ff;
$yellow: #FAAD14;
$antdPurple: #722ED1;
$antdGreen: #52C41A;
$antdOrange: #fa8c16;
$primaryViolet: #B93995;


// black and white
$black: #000;
$white: #fff;
$gray: #888888;
$darkGray: #434343;
$silver: #bfbfbf;
$alto: #d4d4d4;
$alto2: #d9d9d9;

// blue
$blue: #0f4bff;
$lightBlue: #bbe6f0;
$fadedBlue: #cfdbff;
$nepal: #96b8c0;

// orange and yellow
$darkOrange: #f56600;
$lightOrange: #ff8633;

// red and pink
$fire: #ae2a00;
$red: #ED213C;


$backgroundImage: linear-gradient(90deg, #B93995, #EE1E3B);
$purple: #B93995;