.navFooter {
  margin: 0px 60px;
  &.isMobile {
    margin: 0px;
  }
}
.copyRight {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #6d6e70;
  &.is-mobile {
    text-align: center;
  }
}
.footerRight {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #231f20;
  padding-right: 10px;
}
.dividerStyle {
  background-color: #dadce0;
}
.footerContainer {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  position: relative;
  &.is-mobile {
    justify-content: center;
    gap: 10px;
  }
}

:global(.ant-layout-footer) {
  background-color: #f4f5fa !important;
  
}
