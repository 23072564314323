@import 'styles/globals';


.tableTopContainer {
  padding-bottom: 1.5rem
}

.titleContainer {
  display: flex; 
  justify-content: space-between;
  padding-bottom: 2rem;
  gap: 2px;
}
.optionsStyle{
  padding-right: 20px !important;
  margin-bottom: 10px;
}