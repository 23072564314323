.bodyContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}
.heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: rgba(0, 0, 0, 0.87);
}
.heading_two {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #231f20;
}
:global(.ant-tabs-tab:hover) {
  color: #BC012D;
}
:global(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn) {
  color: #BC012D;
}