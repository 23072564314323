.navigationFooterContainer {
    margin-top: 50px;
    margin-bottom: 20px;

    ul {
        padding-left: 0;

        li {
            list-style-type: none;
            margin: 10px 0px;
            font-weight: 300;
            
            a {
                color: black;
            }
        }
    }

    .eventsSection {
        .messageChannel {
            font-size: 28px;
            margin-left: 8px;
            color: rgba(0,0,0,.85);
        }
        #slack {
            font-weight: bold;
        }
        .socialMediaTile {
            margin-top: 20px;
        }
        .socialMediaIcon {
            padding: 10px;
            margin-right: 15px;
            border: 1px solid gray;
            border-radius: 50%;
        }
        #facebookIcon {
            padding: 10px 17px;
        }
    }
}